import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";
import ServiceDetails from "../sharedLandingcomponents/Service_Details_Comp";

const ServiceDetailsPage = () => {
  return (
    <div className="bg-blue-950 min-h-[400px]">
      <div className="container mx-auto px-4 py-8">
        <ServiceDetails />
      </div>
    </div>
  );
};

export default ServiceDetailsPage;

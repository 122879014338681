import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { IList } from "../../../utils/generalType";
import Slide from "react-reveal/Slide";

const TrustedBrandComp = () => {
  const trustedClient: IList[] = [
    { image: `${process.env.PUBLIC_URL + "/images/brands/brand1.jpg"}` },
    { image: `${process.env.PUBLIC_URL + "/images/brands/brand2.jpg"}` },
    { image: `${process.env.PUBLIC_URL + "/images/brands/brand3.jpg"}` },
    { image: `${process.env.PUBLIC_URL + "/images/brands/brand4.jpg"}` },
    { image: `${process.env.PUBLIC_URL + "/images/brands/brand5.jpg"}` },
    { image: `${process.env.PUBLIC_URL + "/images/brands/brand6.jpg"}` },
  ];

  return (
    <div className="bg-blue-950 text-white py-12">
      {/* Trusted */}
      <div className="container mx-auto px-4">
        <Slide up>
          <div className="text-center mb-8">
            <h2 className="text-5xl font-bold fontJostBold">
              <span className="text-sky-200">Our</span> Clients
            </h2>
            <p className="text-base font-medium fontJostRegular">
              Check our best clients & partners
            </p>
          </div>
          <div>
            <Swiper
              // install Swiper modules
              modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              spaceBetween={5}
              slidesPerView={5}
              breakpoints={{
                320: {
                  slidesPerView: 2,
                  spaceBetween: 15,
                },
                // when window width is >= 600px
                600: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                // when window width is >= 840px
                840: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
              }}
            >
              {trustedClient.map((item: IList, index: number) => (
                <SwiperSlide key={index}>
                  <div className="px-1 bg-white rounded-3xl">
                    <img
                      className="mt-2 mb-2 rounded-lg"
                      src={item.image}
                      alt={`Trusted brand ${index + 1}`}
                      style={{ maxWidth: "100%" }}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Slide>
      </div>
      {/* Trusted */}
    </div>
  );
};

export default TrustedBrandComp;

import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";

type IMotionCompGeneral = {
  showRotateLeft?: boolean;
  showRotateRight?: boolean;
  showMoveLeft?: boolean;
  showMoveRight?: boolean;
  rotateLeftImg?: string;
  rotateRightImg?: string;
  moveLeftImg?: string;
  moveRightImg?: string;
};

export const MotionCompGeneral: React.FC<IMotionCompGeneral> = ({
  showMoveLeft,
  showRotateRight,
  showMoveRight,
  showRotateLeft,
  rotateLeftImg,
  rotateRightImg,
  moveLeftImg,
  moveRightImg,
}) => {
  return (
    <>
      {!showMoveLeft &&
      !showMoveRight &&
      !showRotateLeft &&
      !showRotateRight ? (
        ""
      ) : (
        <div style={{ height: "40px", position: "relative" }}>
          {showRotateLeft ? (
            <span className="implement_rotate">
              <img
                style={{ maxWidth: "80%", width: "300px" }}
                alt="heroImage"
                src={
                  rotateLeftImg ||
                  `${process.env.PUBLIC_URL + "/images/homePage/spec-el-1.png"}`
                }
              />{" "}
              &nbsp;
            </span>
          ) : (
            ""
          )}

          {showRotateRight ? (
            <span className="implement_rotate_right">
              <img
                style={{ maxWidth: "80%", width: "300px" }}
                alt="heroImage"
                src={
                  rotateRightImg ||
                  `${process.env.PUBLIC_URL + "/images/homePage/spec-el-1.png"}`
                }
              />{" "}
              &nbsp;
            </span>
          ) : (
            ""
          )}

          {showMoveLeft ? (
            <span className="implement_move_left ">
              <img
                style={{ maxWidth: "80%", width: "30px" }}
                alt="heroImage"
                src={
                  moveLeftImg ||
                  `${process.env.PUBLIC_URL + "/images/homePage/img-7.png"}`
                }
              />{" "}
              &nbsp;
            </span>
          ) : (
            ""
          )}

          {showMoveRight ? (
            <span className="implement_move_right ">
              <img
                style={{ maxWidth: "80%", width: "30px" }}
                alt="heroImage"
                src={
                  moveRightImg ||
                  `${process.env.PUBLIC_URL + "/images/homePage/img-7.png"}`
                }
              />{" "}
              &nbsp;
            </span>
          ) : (
            ""
          )}

          <br />
        </div>
      )}
    </>
  );
};

export default MotionCompGeneral;

import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";
import PagerHeaderComp from "../sharedLandingcomponents/PagesHeaderComp";
import MotionCompGeneral from "../sharedLandingcomponents/Motion_Comp";
import ServiceSlide from "../sharedLandingcomponents/Service_Slide_Comp";
import TestimonyComp from "../sharedLandingcomponents/Testimony_Comp";
import Slide from "react-reveal/Slide";
import { IList } from "../../../utils/generalType";
import CounterComp from "../sharedLandingcomponents/Counter_Comp";

const AboutPage = () => {
  const counters: IList[] = [
    { title: "Experience", subTitle: "YEARS", value: 5 },
    { title: "Clients", subTitle: "SATISFIED", value: 20 },
    { title: "Finish", subTitle: "PROJECT", value: 50 },
    { title: "Partnerships", subTitle: "STRATEGIC", value: 14 },
  ];

  return (
    <div className="bg-blue-950 min-h-screen">
      <PagerHeaderComp
        title="About Us"
        subTitle="Illuminating Your Path to Success"
      />

      {/* Content  */}
      <div className="bg-blue-950 text-white fontJostRegular">
        {/* Motions for Traffic */}
        <div className="hidden md:block">
          <MotionCompGeneral
            showMoveLeft={true}
            showMoveRight={true}
            showRotateRight={true}
            rotateRightImg={`${
              process.env.PUBLIC_URL + "/images/homePage/spec-el-2.png"
            }`}
          />
        </div>

        <div className="container mx-auto px-4">
          {/* First Row */}
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 p-2">
              <Slide up>
                <h2 className="text-4xl font-bold fontJostBold">
                  At ClearWater, we are passionate about crafting narratives
                  that connect and building reputations that endure.
                </h2>
                <p className="text-base mt-8">
                  At Clear Water Communication, we offer expert online
                  reputation management services that help businesses maintain a
                  positive image and build trust with their audience. Our
                  transparent, results-driven approach ensures your brand's
                  reputation stays protected and accurately reflects the values
                  you stand for. Whether it's managing reviews, curating
                  content, or mitigating crises, we provide clear solutions for
                  a cleaner digital presence.
                </p>

                <p className="text-base mt-8">
                  Our service is able to mitigate negative publications targeted
                  at your brand, and/or its key stakeholders, and even other
                  personnel whose conduct may have an impact on the firm. <br />
                  Clear Water PR offers advanced online reputation management
                  (ORM) services, that conduct brand listening on social media
                  platforms, as well as search engine audits, in order to ensure
                  your image stays clean as a whistle.
                </p>
              </Slide>
            </div>

            <div className="w-full md:w-1/2 p-2 flex flex-wrap">
              <Slide up>
                <img
                  className="max-w-4/5 w-100 h-[150] rounded-xl object-cover"
                  alt="About Us"
                  src={`${
                    process.env.PUBLIC_URL +
                    "/images/aboutPage/img-6-copyright-890x664.jpg"
                  }`}
                />
              </Slide>
            </div>
          </div>

          {/* Second Row */}
          <div className="flex flex-wrap mt-12">
            <div className="w-full md:w-1/2 p-2 text-center">
              <Slide up>
                <img
                  className="w-4/5 h-[150] rounded-xl object-cover"
                  src={`${
                    process.env.PUBLIC_URL +
                    "/images/aboutPage/about-5-copyright-b.jpg"
                  }`}
                  alt="About Us"
                />
              </Slide>
            </div>

            <div className="w-full md:w-1/2 p-2">
              <Slide up>
                <div className="bg-sky-950 p-6 rounded-lg mb-6">
                  <p className="text-base">
                    At ClearWater, we know that your reputation is a cornerstone
                    of your identity. Our dedicated team works with you to
                    enhance your public image, navigate crises, and create a
                    lasting impact. We help you shape the conversations that
                    define your brand, ensuring your story resonates with the
                    people who matter most.
                  </p>
                </div>
              </Slide>
              <Slide up>
                <div className="bg-blue-800 p-6 rounded-lg mb-6">
                  <p className="text-base">
                    We specialize in transforming reputations and building
                    enduring legacies. With a blend of strategic expertise and
                    creativity, we craft compelling narratives that elevate your
                    brand and leave a lasting impression. Our commitment to
                    excellence ensures that we deliver results that not only
                    meet but exceed your expectations, fostering an environment
                    where ideas flourish and your brand thrives.
                  </p>
                </div>
              </Slide>
              <Slide up>
                <div className="bg-sky-950 p-6 rounded-lg mb-6">
                  <p className="text-base">
                    At ClearWater, you are at the center of everything we do.
                    Our dedication to clear communication, transparent
                    processes, and a client-first approach guarantees that your
                    success is our top priority. Trust us to stand by your side
                    every step of the way, guiding you towards shaping
                    perceptions and building a reputation that lasts.
                  </p>
                </div>
              </Slide>
            </div>
          </div>
        </div>
      </div>

      {/* Counter */}
      <section>
        <CounterComp />
      </section>
      {/* Counter */}
      {/* <OurTeamComp /> */}
      <ServiceSlide />
      {/* <TestimonyComp /> */}
    </div>
  );
};

export default AboutPage;

import { IList } from "../../../utils/generalType";
import ContactComp from "../sharedLandingcomponents/Contact_Comp";
import MotionCompGeneral from "../sharedLandingcomponents/Motion_Comp";
import PagerHeaderComp from "../sharedLandingcomponents/PagesHeaderComp";

const ContactPage = () => {
  const contacts: IList[] = [
    {
      title: "Call Us",
      subTitle: "667-755-1285",
      content: (
        <a href="tel:667-755-1285" className="text-sky-200 hover:underline">
          <u>667-755-1285</u>
        </a>
      ),
      image: `${process.env.PUBLIC_URL + "/images/contact/customer_care.jpg"}`,
    },
    {
      title: "Request A Quote",
      subTitle: "outreach@clearwater-pr.com",
      content: (
        <a
          href="mailto:outreach@clearwater-pr.com"
          className="text-sky-200 hover:underline"
        >
          <u>outreach@clearwater-pr.com</u>
        </a>
      ),
      image: `${process.env.PUBLIC_URL + "/images/contact/get_quote.jpg"}`,
    },
    {
      title: "Our Office",
      subTitle: "Maryland, USA",
      image: `${process.env.PUBLIC_URL + "/images/contact/office.jpg"}`,
    },
  ];

  return (
    <div className="bg-blue-950 text-white min-h-screen fontJostRegular">
      <PagerHeaderComp
        title="Contact Us"
        subTitle="Ready to Light Up Your Brand's Future?"
      />

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
        {contacts.map((item: IList, index: number) => (
          <div
            key={index}
            className="bg-blue-800 border-blue-800 text-white border rounded-lg shadow-md p-4 flex flex-col items-center"
          >
            <img
              src={item.image}
              alt={item.title}
              className="w-full h-40 object-cover rounded-lg mb-4"
            />
            <h4 className="text-xl font-semibold mb-2 fontJostBold">
              {item.title}
            </h4>
            <p className="text-base font-medium text-sky-200">
              {item.content || item.subTitle}
            </p>
          </div>
        ))}
      </div>

      {/* <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7245.679170685459!2d3.5420261087969163!3d6.46448908573654!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bf72131534c03%3A0x7aab22f67df7c10b!2sRd%2035%2C%20Victoria%20garden%20City%2C%20Lekki%20106104%2C%20Lagos!5e0!3m2!1sen!2sng!4v1724674696101!5m2!1sen!2sng"
        width="100%"
        height="650"
        style={{ border: "0" }}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Google Map Embed"
      ></iframe> */}

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24616.20187928434!2d-77.7680929462086!3d39.53648473201359!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c9f02ad24818c1%3A0xe81fc30b8c70f80b!2sFairplay%2C%20MD%2021733%2C%20USA!5e0!3m2!1sen!2sng!4v1726627606330!5m2!1sen!2sng"
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowFullScreen={true}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Google Map Embed"
      ></iframe>

      <div className="bg-blue-950 text-white p-8 mt-8">
        <MotionCompGeneral
          showMoveLeft={true}
          showMoveRight={true}
          showRotateRight={true}
          rotateRightImg={`${
            process.env.PUBLIC_URL + "/images/homePage/spec-el-2.png"
          }`}
        />
        <div className="text-center">
          <p className="text-xl font-semibold mb-4">
            We're just a message away from starting your transformation.
          </p>
          <p className="text-base">
            Have questions or ready to take the first step towards a brighter
            brand image? Reach out to us! Whether it's for a quick inquiry or to
            schedule a detailed consultation, our contact team is eager to hear
            from you. Let's start crafting your success story today.
          </p>
        </div>
      </div>

      <ContactComp />
    </div>
  );
};

export default ContactPage;

import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPagesRoute from "./pages/landingpages/landingPagesRoute";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<LandingPagesRoute />} />
        <Route path="landing/*" element={<LandingPagesRoute />} />
        <Route path="*" element={<LandingPagesRoute />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;

import { IList } from "../../../utils/generalType";
import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";
import MotionCompGeneral from "./Motion_Comp";
import Slide from "react-reveal/Slide";
import { Link } from "react-router-dom";

export const CoreValueComp = () => {
  const coreValue: IList[] = [
    {
      title: "Security",
      caption:
        "We protect what matters most, ensuring peace of mind in every step.",
      image: `${process.env.PUBLIC_URL + "/images/home/ChartPie.png"}`,
      iconColor: "bg-yellow-100",
    },
    {
      title: "Strength",
      caption:
        "Our foundation is built on resilience, empowering us to rise above any challenge.",
      image: `${process.env.PUBLIC_URL + "/images/home/LayersHalf.png"}`,
      iconColor: "bg-red-100",
    },
    {
      title: "Courage",
      caption:
        "We face the future boldly, driven by a fearless commitment to innovation and excellence.",
      image: `${process.env.PUBLIC_URL + "/images/home/BarChartLine.png"}`,
      iconColor: "bg-blue-100",
    },
    {
      title: "Branding",
      caption:
        "Exceptional results are our ultimate goal. We go above and beyond to deliver outcomes.",
      image: `${process.env.PUBLIC_URL + "/images/home/People.png"}`,
      iconColor: "bg-green-100",
    },
  ];
  return (
    <>
      {/* Core Value */}
      <div className="bg-blue-800 text-white text-center py-2">
        {/* Motions for Traffic */}
        <div className="hidden md:block">
          <MotionCompGeneral
            showMoveLeft={true}
            showMoveRight={true}
            showRotateRight={true}
            rotateRightImg={`${
              process.env.PUBLIC_URL + "/images/homePage/spec-el-2.png"
            }`}
          />
        </div>
        <div className="container mx-auto px-4 fontJostRegular">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-6/12 md:w-6/12 flex flex-col">
              <div className={"mt-12 px-4"}>
                <Slide up>
                  <h2 className="text-left text-5xl fontJostRegular font-semibold mb-8">
                    <span className="text-sky-200">Perfect</span> PR solution{" "}
                    <br /> for your business
                  </h2>
                  <p className="mt-4 text-base text-left">
                    At clearwater-pr, we have a proven and demonstrable track
                    record of assisting transnational corporations clear up the
                    muddied waters around the reputation of their business
                  </p>
                  <p className="mt-4 text-base text-left">
                    With over 5,000 online removals, we ensure your search
                    engine results page are free of slander, malicious attacks
                    and reports that could affect your company's financial
                    position, today or in the future.
                  </p>

                  <p className="mt-20  mb-10 text-left">
                    <Link
                      className="btn bg-sky-950 px-8 py-5 text-white font-semibold rounded-3xl shadow-lg mb-4 mr-4"
                      to={"/landing/about"}
                    >
                      <span> Read More </span>
                    </Link>
                  </p>
                </Slide>
              </div>
            </div>
            <div className="w-full lg:w-6/12 md:w-6/12 flex">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2 justify-center mt-8">
                {coreValue.map((item: IList, index: number) => (
                  <Slide up key={index}>
                    <div className=" p-4 border rounded-lg">
                      <div className="text-center p-4">
                        <span className={"p-4 rounded-xl " + item?.iconColor}>
                          <img
                            className="mx-auto"
                            src={item.image}
                            alt={item.title}
                            style={{ maxWidth: "60%", width: "30px" }}
                          />
                        </span>
                        <h4 className="font-bold text-xl mt-4 fontJostBold">
                          {item.title}
                        </h4>
                        <p className="mt-2 text-base">{item.caption}</p>
                      </div>
                    </div>
                  </Slide>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Core Value */}
    </>
  );
};

export default CoreValueComp;

import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { IList } from "../../../utils/generalType";
import MotionCompGeneral from "./Motion_Comp";
import Slide from "react-reveal/Slide";
import { services } from "./Services/service.utils";

const whatWeDo: IList[] = services;

export const ServiceSlide = () => {
  return (
    <>
      <Slide up>
        {/* Our Service Slide */}
        <div className="bg-sky-950 text-center text-white py-12 fontJostRegular">
          <div className="container mx-auto px-4">
            {/* Motions for Implement*/}
            <div className="hidden sm:block">
              <MotionCompGeneral
                showMoveLeft={true}
                showMoveRight={true}
                showRotateLeft={true}
                moveRightImg={`${
                  process.env.PUBLIC_URL + "/images/homePage/img-2-1.png"
                }`}
              ></MotionCompGeneral>
            </div>

            <h2 className="text-5xl font-bold fontJostBold">
              <span className="text-sky-200">What</span> We Offer
            </h2>
            <p className="text-base mt-4">
              Our boutique suite of public relations and digital communications
              services assist your business in maintaining a positive image
              <br /> whilst building trust with all stakeholders. 
            </p>
          </div>
        </div>
        {/* Our Service Slide */}

        {/*  Service Slide */}
        <div className="bg-sky-950 text-white py-8 fontJostRegular">
          {/* Motions for Category */}
          <MotionCompGeneral
            showMoveLeft={true}
            showMoveRight={true}
          ></MotionCompGeneral>
          <div className="container mx-auto px-4">
            <Swiper
              // install Swiper modules
              modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
              navigation
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              spaceBetween={15}
              slidesPerView={3}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                600: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                840: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
              }}
            >
              {whatWeDo.map((item: IList, index: number) => (
                <SwiperSlide key={index}>
                  <div className="p-4">
                    <div className="rounded-xl bg-blue-950 shadow-lg text-left">
                      <img
                        className="rounded-xl mx-auto mb-4 object-cover"
                        src={item.image}
                        alt="service"
                        style={{
                          width: "100%",
                          height: "250px",
                        }}
                      />{" "}
                      <div className="p-4">
                        <h4 className="text-2xl font-bold fontJostBold mb-2">
                          {item.title}
                        </h4>

                        <p className="text-gray-100 mb-2">{item.caption}</p>
                        <Link
                          to={item.link || "/"}
                          className="text-sky-500 hover:underline"
                        >
                          Know More
                        </Link>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        {/* Service Slide */}
      </Slide>
    </>
  );
};

export default ServiceSlide;

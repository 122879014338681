import { useRef } from "react";
import useScrollTriggeredCountUp from "../../../hooks/useScrollTriggeredCountUp";
import { IList } from "../../../utils/generalType";
import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";

export const CounterComp = () => {
  const ref = useRef<HTMLDivElement>(null);
  const count_1 = useScrollTriggeredCountUp(ref, 5);
  const count_2 = useScrollTriggeredCountUp(ref, 20);
  const count_3 = useScrollTriggeredCountUp(ref, 50);
  const count_4 = useScrollTriggeredCountUp(ref, 14);

  const counters: IList[] = [
    { title: "Experience", subTitle: "YEARS", value: count_1 },
    { title: "Clients", subTitle: "SATISFIED", value: count_2 },
    { title: "Finish", subTitle: "PROJECT", value: count_3 },
    { title: "Partnerships", subTitle: "STRATEGIC", value: count_4 },
  ];

  return (
    <>
      {/* Our number */}
      <div className="bg-blue-800 text-white py-32 fontJostRegular">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-center">
            {counters.map((item: IList, index: number) => (
              <div key={index} className="w-full lg:w-1/4 md:w-1/2 p-2">
                <div className="text-center">
                  <div ref={ref} className="text-4xl font-bold fontJostBold">
                    {item.value}
                  </div>
                  <div className="mt-2">
                    <span className="block text-base">{item.subTitle}</span>
                    <span className="block font-bold fontJostBold text-xl">
                      {item.title}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Our number */}
    </>
  );
};

export default CounterComp;
